@font-face {
  font-family: 'Causten Round';
  src: url('../assets/fonts/CaustenRound-Black.woff2') format('woff2'),
  url('../assets/fonts/CaustenRound-Black.woff') format('woff') {}
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Causten Round';
  src: url('../assets/fonts/CaustenRound-Bold.woff2') format('woff2'),
  url('../assets/fonts/CaustenRound-Bold.woff') format('woff') {}
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Causten Round';
  src: url('../assets/fonts/CaustenRound-Light.woff2') format('woff2'),
  url('../assets/fonts/CaustenRound-Light.woff') format('woff') {}
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Causten Round';
  src: url('../assets/fonts/CaustenRound-Medium.woff2') format('woff2'),
  url('../assets/fonts/CaustenRound-Medium.woff') format('woff') {}
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Causten Round';
  src: url('../assets/fonts/CaustenRound-Regular.woff2') format('woff2'),
  url('../assets/fonts/CaustenRound-Regular.woff') format('woff') {}
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
