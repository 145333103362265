@import '../../../../styles/animations';

.container {
  .wrapper {
    overflow: hidden;
    height: 90vh;
    margin-top: var(--primary-space);
    .total, .title, .customer, .customersTitle, .contact {
      @include pulseSkeletonAnimation(1s, 0s); }
    .searchBar {
      display: flex;
      align-items: center;
      position: relative;
      height: 36px;
      transition: 0.5s;
      margin: 0 var(--primary-space);
      background: var(--gray-7);
      border-radius: 4px;
      .searchIcon {
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: var(--blue-1);
        margin: 0 var(--primary-space); }
      .input {
        width: 100%;
        font-size: var(--regular-font-size);
        border-radius: 4px;
        background: var(--gray-7); } }
    .total {
      display: flex;
      padding: 0 var(--primary-space);
      align-items: center;
      margin: var(--primary-space) 0 0 0;
      .text {
        width: 90px;
        height: 22px;
        border-radius: 4px;
        margin: 0 auto 0 0;
        background-color: var(--gray-4); }
      .value {
        width: 90px;
        height: 32px;
        border-radius: 4px;
        background-color: var(--gray-4); } }
    .title {
      width: 116px;
      height: 28px;
      border-radius: 4px;
      background-color: var(--gray-4);
      margin: var(--primary-space) var(--primary-space); }
    .customer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px var(--primary-space);
      .info {
        display: flex;
        align-items: center;
        .icon {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          border-radius: 4px;
          background-color: var(--gray-4); }
        .mid {
          margin: 4px 0;
          .name {
            width: 222px;
            height: 22px;
            border-radius: 4px;
            margin-bottom: 4px;
            background-color: var(--gray-4); }
          .dateText {
            width: 145px;
            height: 18px;
            border-radius: 4px;
            background-color: var(--gray-4); } } }
      .value {
        width: 28px;
        height: 20px;
        border-radius: 4px;
        background-color: var(--gray-4); } }
    .customersTitle {
      width: 250px;
      height: 28px;
      border-radius: 4px;
      background-color: var(--gray-4);
      margin: var(--primary-space) var(--primary-space); }
    .customersDate {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      background-color: var(--gray-4);
      margin: 16px 0 0 16px; }
    .contact {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px var(--primary-space);
      .info {
        display: flex;
        align-items: center;
        .icon {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          border-radius: 4px;
          background-color: var(--gray-4); }
        .mid {
          margin: 4px 0;
          .name {
            width: 222px;
            height: 22px;
            border-radius: 4px;
            margin-bottom: 4px;
            background-color: var(--gray-4); }
          .dateText {
            width: 145px;
            height: 18px;
            border-radius: 4px;
            background-color: var(--gray-4); } } }
      .value {
        width: 28px;
        height: 20px;
        border-radius: 4px;
        background-color: var(--gray-4); } } } }
