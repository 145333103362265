@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.5s);
  @include animationDelay(0.3s);
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white-1);
  .title {
    @include fromLeftAnimation(0.7s, 200px);
    font-weight: bold;
    font-size: 24px;
    color: var(--blue-1);
    letter-spacing: -0.1px;
    margin: 8px 0 0 16px; } }
