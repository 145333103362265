@import '../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.5s);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  .item {
    width: calc( 100% - 24px );
    height: 56px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @include listAnimation(8);
    &:hover {
      .name, .icon, .image {
        @include tapItemListAnimation(); } }
    .icon {
      font-size: 22px;
      color: var(--blue-1);
      width: 25px;
      display: flex;
      justify-content: center;
      padding: 4px; }
    .image {
      max-width: 25px;
      max-height: 25px; }
    .name {
      margin: 0 auto 0 10px;
      font-weight: 400;
      font-size: var(--regular-font-size);
      line-height: 20px;
      color: var(--blue-1); } } }
