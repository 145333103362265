.steps {
  width: calc( 100% - 40px );
  font-weight: 700;
  font-size: 24px;
  color: var(--blue-1);
  .clarification {
    font-size: var(--regular-font-size);
    font-weight: 400;
    margin-left: 4px; }
  .step {
    font-size: 12px;
    line-height: 20px;
    color: var(--gray-6); } }
