.container {
  margin: 24px 0;
  width: 100%;
  position: relative;
  .title {
    letter-spacing: -0.1px;
    color: var(--blue-1);
    font-size: 24px;
    display: flex;
    align-items: center;
    height: 40px;
    margin: 0 var(--primary-space);
    .text {
      flex: 1; }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      width: 39px;
      height: 39px;
      border: 1px solid var(--gray-5);
      border-radius: 100%;
      margin-left: 8px;
      position: relative; }
    .point {
      position: absolute;
      height: 8px;
      width: 8px;
      background-color: var(--red-1);
      border-radius: 100%;
      right: 4px;
      top: 0; } }
  .total {
    position: absolute;
    right: 16px;
    top: 64px;
    color: var(--blue-1);
    font-size: var(--regular-font-size); } }
