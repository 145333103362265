@import '../../../../styles/animations';

.wrapper {
  width: 100%;
  margin-bottom: var(--primary-space);
  @include fadeInAnimation(0.5s);
  overflow-x: hidden;
  .title {
    margin: 24px 0 16px 16px;
    font-size: 24px;
    color: var(--blue-1);
    letter-spacing: -0.1px; }
  .filter {
    @include listAnimation(6);
    width: var(--primary-total-width);
    padding: 0 var(--primary-space);
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: var(--blue-1);
    letter-spacing: -0.1px; } }
