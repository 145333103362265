@import '../../styles/animations';

.wrapper {
  border: none;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  background-color: var(--green-1);
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  &:disabled {
    cursor: default;
    background: var(--gray-9);
    box-shadow: none;
    animation: none;
    .icon, .text {
      color: var(--white-1); } }
  &.small {
    height: 32px;
    padding: 6px 0; }
  &.medium {
    height: 40px;
    padding: 10px 0; }
  &.large {
    height: 48px;
    padding: 14px 0; }
  &.outline {
    background-color: var(--white-1);
    border: 1px solid var(--gray-5);
    .icon, .text {
      color: var(--gray-8); }
    &:disabled {
      .icon, .text {
        color: var(--gray-5); } } }
  .icon, .text {
    @include fadeInAnimation(0.4s);
    font-size: var(--regular-font-size);
    font-weight: 700;
    color: var(--white-1);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
  .text {
    height: 22px;
    align-items: flex-end;
    line-height: 20px; }
  .leftIcon {
    width: 24px;
    height: 24px;
    font-weight: 400;
    font-size: 20px;
    margin: 0px 8px 0 0; }
  .bold {
    font-weight: 700; } }

@keyframes tap {
  50% {
    border-width: 5px;
    transform: scale(1.1); } }
