@import '../../../../styles/animations';

.wrapper {
  width: var(--primary-total-width);
  padding: var(--primary-space);
  display: grid;
  grid-template-columns: 1fr 2.4fr;
  column-gap: 16px;
  position: fixed;
  bottom: 0;
  @include fromBottomAnimation(0.5s, 100px);
  background-color: var(--white-1); }
