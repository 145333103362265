@import '../../styles/animations';

.container {
  @include fadeInAnimation(0.5s);
  @include animationDelay(0.3s);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  overflow: hidden;
  .steps {
    margin-top: 20px;
    width: calc( 100% - 40px ); } }
