@import '../../../../styles/animations';

.container {
  padding-bottom: 8px;
  width: 100%;
  @include fadeInAnimation(0.5s);
  @include animationDelay(0.3s);
  padding-top: 80px;
  .header {
    width: var(--primary-total-width);
    padding: 24px 16px 8px 16px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include fromTopAnimation(0.5s, 100px);
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: var(--white-1);
    .iconLeft {
      padding-right: 8px;
      width: 24px;
      text-align: center; }
    .wrapper {
      height: 100%;
      width: var(--primary-total-width);
      padding: 0 var(--primary-space);
      background: var(--gray-7);
      border: 1px solid var(--gray-5);
      border-radius: 4px;
      display: flex;
      align-items: center;
      transition: 0.5s;
      &:focus-within {
        border-color: var(--gray-11); }
      &.noResults {
        border-color: var(--red-1); }
      .searchIcon {
        width: 16px;
        color: var(--blue-1);
        margin-right: 8px; }
      .input {
        color: var(--blue-1);
        font-size: var(--regular-font-size);
        background-color: transparent;
        flex: 1;
        &:placeholder {
          color: var(--gray-6); } } } } }
