 :root {
  --font: "Causten Round", "causten", Arial, Helvetica, system-ui, sans-serif;

  --white-1: #FFFFFF;
  --black-1: #000000;
  --black-2: #363636;
  --gray-1: #AFAFAF;
  --gray-2: #F8F8F8;
  --gray-3: #EBEBEB;
  --gray-4: #EBEDF1;
  --gray-5: #DDDFE8;
  --gray-6: #A2A6BD;
  --gray-7: #F9F9FA;
  --gray-8: #666A8F;
  --gray-9: #C6C9D8;
  --gray-10: #FAFBFC;
  --gray-11: #8084A2;
  --red-1: #FF0136;
  --red-2: #FF3E66;
  --blue-1: #2B2845;
  --green-1: #00B56B;
  --yellow-1: #FF9F26;

  --transparent-1: rgba(43, 40, 69, 0.6);
  --transparent-2: rgba(255, 255, 255, 0.2);
  --transparent-3: rgba(0, 0, 0, 0.7);
  --transparent-4: rgba(243, 240, 240, 0.201);

  --ob-cover-background: var(--transparent-1);

  --ob-cover-z-index: 99998;
  --ob-tooltip-z-index: 99999;
  --window-cover-z-index: 9999999;

  --primary-space: 16px;
  --primary-total-width: calc( 100% - ( var(--primary-space) * 2 ));
  --primary-total-height: var(--primary-total-width);

  --regular-font-size: 16px; }
