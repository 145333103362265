.switch {
  position: relative;
  display: flex;
  width: 48px;
  height: 24px; }
.input {
  opacity: 0;
  width: 0;
  height: 0; }
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-4);
  transition: 0.4s;
  &:before {
    position: absolute;
    bottom: 2px;
    left: 2px;
    content: "";
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    transition: 0.4s; }
  .input:checked + & {
    background-color: var(--green-1); }
  .input:checked + &:before {
    transform: translateX(24px); }
  &.round {
    border-radius: 20px; }
  &.round:before {
    border-radius: 50%; } }
