@import '../../../../styles/animations';

.item {
  width: calc( 100% - 24px );
  height: 62px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  color: var(--blue-1);
  background-color: var(--white-1);
  margin-bottom: 8px;
  border-radius: 4px;
  .icon {
    margin: 0 14px 0 6px;
    font-size: 14px;
    color: var(--blue-1); }
  .description {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--blue-1);
    line-height: 20px;
    .subtitle {
      font-size: 12px; } }
  .arrow {
    transform: rotate(180deg);
    margin: 0 5px 0 14px;
    color: var(--blue-1);
    font-size: 12px; } }
