.wrapper {
  width: calc( 100% - 40px );
  padding: var(--primary-space) 20px;
  .title {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.1px;
    text-transform: capitalize;
    color: var(--blue-1); }
  .calendar {
    margin: var(--primary-space) 0;
    display: grid;
    row-gap: 8px;
    grid-template-columns: repeat(7, 1fr);
    .cell, .empty, .dayName {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-size: 18px;
      letter-spacing: -0.1px;
      color: var(--black-1); }
    .dayName {
      font-size: var(--regular-font-size);
      color: var(--gray-8); }
    .cell {
      transition: 0.3s background;
      .day {
        display: flex;
        align-items: center;
        justify-content: center; }
      &.initRange {
        background: linear-gradient(to right, var(--white-1) 0%, var(--white-1) 50%, var(--gray-4) 50%, var(--gray-4) 50%); }
      &.endRange {
        background: linear-gradient(to left, var(--white-1) 0%, var(--white-1) 50%, var(--gray-4) 50%, var(--gray-4) 50%); }
      &.selected {
        .day {
          font-weight: 700;
          height: 100%;
          width: 32px;
          color: var(--white-1);
          background-color: var(--blue-1);
          border-radius: 100%;
          z-index: 10; } }
      &.today {
        color: var(--green-1); }
      &.range {
        background-color: var(--gray-4);
        position: relative; }
      &:nth-child(7n+1), &.first {
        border-radius: 25px 0 0 25px; }
      &:nth-child(7n+7), &:last-child {
        border-radius: 0 25px 25px 0; }
      &.notRange {
        background: none; }
      &.isDisabled {
        color: var(--gray-8); } } } }
