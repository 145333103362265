@import '../../styles/animations';

.transactions {
  overflow: hidden;
  margin-top: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--gray-4);
  &:last-child {
    border: none; }
  .date {
    margin: 0 0 8px 16px;
    font-size: var(--regular-font-size);
    color: var(--blue-1);
    @include fromLeftAnimation(0.5s, 50px);
    animation-fill-mode: both;
    text-transform: capitalize; } }
.loading {
  display: flex;
  justify-content: center;
  margin: 150px 0; }
