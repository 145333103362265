@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.5s);
  width: 100%;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .background {
    @include fadeInAnimation(0.5s);
    display: flex;
    width: 100%;
    height: calc( 32px - 16px );
    background-color: var(--gray-10);
    padding: 8px 0;
    align-items: center;
    justify-content: center;
    .lastUsed {
      @include fadeInAnimation(0.5s);
      width: calc( 100% - 40px );
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: var(--gray-8);
      .left {
        align-self: start; }
      .right {
        align-self: end; }
      .clock {
        display: inline-block;
        transform: scaleX(-1);
        color: var(--gray-9); }
      .close {
        font-size: 10px;
        color: var(--gray-8); }
      .name {
        @include fadeInAnimation(0.5s);
        color: var(--gray-8);
        margin-left: 10px; }
      .iconArrow {
        display: inline-block;
        margin-left: 11px;
        transform: rotate(45deg); } } }
  .list {
    width: calc( 100% - 40px );
    .createProvider {
      display: flex;
      align-items: center;
      margin-top: 8px;
      font-size: var(--regular-font-size);
      line-height: 20px;
      @include fadeInAnimation(0.5s);
      @include animationDelay(1s);
      .icon {
        color: var(--green-1);
        font-size: 22px; }
      .text {
        display: inline-block;
        margin-left: 8px;
        font-size: var(--regular-font-size);
        line-height: 20px; }
      .createProviderName {
        font-weight: 700;
        margin-left: 5px; } }
    .item {
      @include listAnimation(10);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 8px 0;
      transition: 0.5s;
      text-align: left;
      color: var(--blue-1);
      .result {
        font-size: 12px;
        line-height: 16px;
        .bold {
          font-weight: bold; } }
      .icon {
        transform: rotate(45deg);
        font-size: 12px;
        color: var(--gray-8); } } } }
