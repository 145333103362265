@import '../../../../styles/animations';

.wrapper {
  color: var(--white-1);
  position: fixed;
  z-index: var(--ob-tooltip-z-index);
  transition: 0.3s;
  width: fit-content;
  &.step-1 {
    top: 85px;
    right: 16px; }
  &.step-2 {
    bottom: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    animation: popUp 0.5s ease 0.3s both; }
  &.step-3 {
    @include popUpAnimation(0.5s);
    top: 80px;
    left: 16px; }
  &.step-4 {
    animation: popUp2 0.5s ease;
    bottom: 130px;
    left: 0;
    right: 0;
    margin: 0 auto; } }

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0); } }

@keyframes popUp2 {
  0% {
    opacity: 0;
    transform: scale(0); } }
