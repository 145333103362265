@import '../../../../styles/animations';

.wrapper {
  width: calc( 100% - 40px );
  padding-top: 8px;
  .title {
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    color: var(--blue-1); }
  .items {
    display: flex;
    gap: 24px;
    justify-content: center;
    margin: var(--primary-space) 26px;
    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      &:nth-child(2) {
        .wrapperIcon {
          animation-delay: 0.3s; } }
      &:nth-child(3) {
        .wrapperIcon {
          animation-delay: 0.5s; } }
      .wrapperIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: var(--gray-4);
        @include PopUpStepAnimation(0.7s);
        animation-fill-mode: both;
        .icon {
          font-size: var(--regular-font-size);
          line-height: 20px;
          color: var(--blue-1); } }
      .text {
        display: inline-block;
        width: 87px;
        margin-top: 6px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        color: var(--blue-1);
        @include fadeInAnimation(1s); } } }
  .emptyState {
    display: inline-block;
    margin: var(--primary-space) 0;
    font-size: 12px;
    line-height: 16px; } }
