@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.5s);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px; }
  .input {
    width: 100%;
    margin-top: 5px; } }
