@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.3s);
  display: flex;
  flex-direction: column;
  background-color: var(--white-1);
  width: 100%;
  margin-top: 8px;
  overflow: hidden;
  .title {
    font-size: 24px;
    letter-spacing: -0.1px;
    color: var(--blue-1);
    width: 100%;
    padding: var(--primary-space); }
  .letter {
    color: var(--gray-8);
    text-transform: capitalize;
    font-size: 14px;
    padding: 0 var(--primary-space);
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    height: 18px; }
  .card {
    width: var(--primary-total-width);
    padding: 0 var(--primary-space);
    height: 64px;
    display: flex;
    align-items: center;
    color: var(--blue-1);
    @include fadeInAnimation(0.3s);
    .icon {
      font-size: 20px;
      margin-right: 16px; }
    .center {
      font-size: 18px;
      letter-spacing: -0.1px;
      flex: 1;
      .phone {
        color: var(--gray-8);
        font-size: 14px;
        margin-top: 6px; } }
    .link {
      color: var(--green-1);
      font-size: 16px; } }
  .divider {
    margin: 8px 0;
    &:last-child {
      visibility: hidden; } } }
