@import '../../../../styles/animations';

.container {
  @include fromRightSkeletonAnimation(0.5, 300px);
  .wrapper {
    overflow: hidden;
    height: 90vh;
    @include pulseSkeletonAnimation(1s, 0s);
    .title {
      display: flex;
      justify-content: space-between;
      padding: 0 var(--primary-space);
      margin-bottom: 20px;
      .text {
        width: 48px;
        height: 24px;
        background-color: var(--gray-4);
        border-radius: 4px; }
      .value {
        width: 140px;
        height: 32px;
        background-color: var(--gray-4);
        border-radius: 4px; } }
    .balancesBox {
      padding: 0 var(--primary-space);
      display: flex;
      justify-content: space-between;
      margin: 20px 0 var(--primary-space) 0;
      .value {
        width: 81px;
        height: 24px;
        background-color: var(--gray-4);
        border-radius: 4px; }
      .text {
        background-color: var(--gray-4);
        height: 24px;
        border-radius: 4px; }
      &:nth-child(3) {
        .text {
          width: 65px; } }
      &:nth-child(4) {
        .text {
          width: 55px; } }
      &:nth-child(5) {
        .text {
          width: 39px; } }
      &:nth-child(6) {
        .text {
          width: 77px; } }
      &:nth-child(7) {
        .text {
          width: 87px;
          margin-bottom: 22px; } } }
    .incomeTitle {
      width: var(--primary-total-width);
      height: 24px;
      background-color: var(--gray-4);
      border-radius: 4px;
      margin: 26px 16px 16px 16px; }
    .incomeText {
      width: 59px;
      height: 20px;
      background-color: var(--gray-4);
      border-radius: 4px;
      margin: 0 0 var(--primary-space) var(--primary-space); }
    .incomesBox {
      margin-bottom: var(--primary-space);
      padding: 0 var(--primary-space);
      display: flex;
      justify-content: space-between;
      .value {
        width: 81px;
        height: 24px;
        background-color: var(--gray-4);
        border-radius: 4px; }
      .text {
        background-color: var(--gray-4);
        border-radius: 4px;
        height: 24px; }
      &:nth-child(11) {
        .text {
          width: 45px; } }
      &:nth-child(12) {
        .text {
          width: 103px; } }
      &:nth-child(13) {
        .text {
          width: 74px; } }
      &:nth-child(14) {
        .text {
          width: 62px; } }
      &:nth-child(15) {
        .text {
          width: 55px;
          margin-bottom: 8px; } } }
    .expensesTitle {
      width: 59px;
      height: 20px;
      border-radius: 4px;
      margin: 24px 0 16px 16px;
      background-color: var(--gray-4); }
    .expensesBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--primary-space);
      padding: 0 var(--primary-space);
      .value {
        width: 81px;
        height: 24px;
        background-color: var(--gray-4);
        border-radius: 4px; }
      .text {
        height: 24px;
        background-color: var(--gray-4);
        border-radius: 4px; }
      &:nth-child(18) {
        .text {
          width: 45px; } }
      &:nth-child(19) {
        .text {
          width: 103px; } }
      &:nth-child(20) {
        .text {
          width: 74px; } }
      &:nth-child(21) {
        .text {
          width: 62px; } } } } }
