@import '../../styles/animations';

.wrapper {
  position: relative;
  background-color: var(--blue-1);
  padding: 8px;
  border-radius: 4px;
  @include PopUpStepAnimation(0.5s);
  .arrow {
    position: absolute;
    width: 0;
    height: 0;
    &.top {
      top: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 12px solid var(--blue-1); }
    &.bottom {
      bottom: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 12px solid var(--blue-1); }
    &.left {
      left: 8px; }
    &.right {
      right: 8px; }
    &.center {
      left: 0;
      right: 0;
      margin: 0 auto; } } }

