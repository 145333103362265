.spinner {
  margin-top: 2px;
  display: inline-block;
  animation: spin 1s linear infinite;
  background: var(--white-1);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  position: relative; }
.secondType {
  background: var(--gray-8); }
.spinner::before,
.spinner::after {
  content: '';
  position: absolute; }
.spinner::before {
  border-radius: 50%;
  background: linear-gradient(0deg, hsla(0, 0%, 100%, 0.1) 50%, hsla(0, 0%, 100%, 0.3) 100%) 0% 0%,
  linear-gradient(90deg,  hsla(0, 0%, 100%, 0.3)  0%, hsla(0, 0%, 100%, 0.6) 100%) 100%   0%,
  linear-gradient(180deg, hsla(0, 0%, 100%, 0.6)  0%, hsla(0, 0%, 100%, 0.9) 100%) 100% 100%,
  linear-gradient(360deg, hsla(0, 0%, 100%, 0.9)  0%, hsla(0, 0%, 100%, 1  ) 100%) 0% 100% {}
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px; }
.secondType::before {
  border-radius: 50%;
  background: linear-gradient(0deg, hsla(184, 9%, 62%, 0.1) 50%, hsla(184, 9%, 62%, 0.3) 100%) 0% 0%,
  linear-gradient(90deg,  hsla(184, 9%, 62%, 0.3)  0%, hsla(184, 9%, 62%, 0.6) 100%) 100%   0%,
  linear-gradient(180deg, hsla(184, 9%, 62%, 0.6)  0%, hsla(184, 9%, 62%, 0.9) 100%) 100% 100%,
  linear-gradient(360deg, hsla(184, 9%, 62%, 0.9)  0%, hsla(184, 9%, 62%, 1  ) 100%) 0% 100% {}
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px; }
.spinner::after {
  border-radius: 50%;
  top: 2%;
  bottom: 2%;
  left: 2%;
  right: 2%; }
.normal::after {
  background: var(--green-1); }
.secondType::after {
  background: var(--white-1); }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
