@import '../../../../styles/animations';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  width: 100%;
  @include fadeInAnimation(0.5s);
  .left {
    display: flex;
    flex: 0.96;
    align-items: center;
    font-size: 18px;
    color: var(--blue-1);
    .img {
      margin-right: 16px;
      width: 24px;
      height: 24px;
      object-fit: contain; } } }
