@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.5s);
  width: var(--primary-total-width);
  padding: 0 var(--primary-space);
  display: flex;
  flex-direction: column;
  background-color: var(--gray-10);
  min-height: calc( 100vh - 60px );
  .searchBarWrapper {
    width: 100%;
    margin: var(--primary-space) 0; } }
