.value {
  display: flex;
  align-items: center;
  color: var(--blue-1);
  &.info {
    color: var(--gray-8); }
  &.deletion {
    color: var(--gray-6);
    text-decoration: line-through; }
  &.expense {
    color: var(--red-1);
    .minus {
      display: inline; } }
  &.zero {
    color: var(--blue-1);
    .minus {
      display: none; } }
  &.small {
    .number {
      font-size: var(--regular-font-size); }
    .sign {
      font-size: 12px; } }
  &.large {
    .sign {
      font-size: var(--regular-font-size); }
    .number {
      font-size: 28px; } }
  .sign {
    font-size: 10px; }
  .number {
    font-size: 20px; }
  .minus {
    display: none; } }

