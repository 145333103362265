.wrapper {
  padding: 0 var(--primary-space);
  width: var(--primary-total-width);
  margin-bottom: 16px;
  .costumerInfo {
    display: flex;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    .name {
      font-size: 16px;
      line-height: 20px;
      color: var(--gray-8); }
    .item {
      margin-top: 8px;
      font-size: 20px;
      line-height: 24px;
      color: var(--blue-1); } }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--primary-space) 0;
    .title {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.1px;
      color: var(--blue-1); }
    .update {
      font-size: 16px;
      line-height: 20px;
      color: var(--green-1); } } }
