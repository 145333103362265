@import '../../../../styles/animations';

.wrapper {
  color: var(--blue-1);
  .total {
    width: var(--primary-total-width);
    display: flex;
    justify-content: space-between;
    margin: var(--primary-space) 0;
    padding: 0 var(--primary-space);
    align-items: center;
    font-size: 20px;
    letter-spacing: -0.1px; }
  .balances {
    margin: var(--primary-space) 0 24px 0;
    padding: 0 var(--primary-space);
    width: var(--primary-total-width);
    .balanceItem {
      @include listAnimation(5);
      width: 100%;
      height: 42px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      letter-spacing: -0.1px;
      font-size: 18px; } } }
