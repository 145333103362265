@import '../../styles/animations';

.container {
  width: var(--primary-total-width);
  max-height: 100vh;
  padding: 0 var(--primary-space);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include fadeInAnimation(1s);
  .title {
    margin-top: 8px;
    .text {
      font-weight: 700;
      letter-spacing: -0.1px;
      font-size: 24px;
      color: var(--blue-1); } }
  .buttons {
    margin: 23px 0 47px 0;
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    .circle {
      width: 46px;
      height: 46px;
      background-color: var(--gray-4);
      border-radius: 100%;
      @include pulseSkeletonAnimation(1s, 0s); } }
  .divider {
    .line {
      width: calc( 100% + 32px );
      height: 16px;
      background-color: var(--gray-7);
      margin-left: -16px;
      @include pulseSkeletonAnimation(1s, 0s); } }
  .wrapper {
    margin: 30px 0;
    .info {
      @include pulseSkeletonAnimation(1s, 0s);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px 0;
      .text {
        height: 26px;
        background-color: var(--gray-4);
        border-radius: 4px; }
      .value {
        height: 22px;
        background-color: var(--gray-4);
        border-radius: 4px; }
      &:nth-child(1) {
        .text {
          width: 65px; }
        .value {
          width: 77px; } }
      &:nth-child(2) {
        .text {
          width: 55px; }
        .value {
          width: 81px; } }
      &:nth-child(3) {
        .text {
          width: 70px; }
        .value {
          width: 110px;
          height: 32px; } } }
    .infoTwo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px 0;
      @include pulseSkeletonAnimation(1s, 0s);
      .text {
        height: 26px;
        background-color: var(--gray-4);
        border-radius: 4px; }
      .right {
        display: flex;
        align-items: center;
        .value {
          height: 22px;
          background-color: var(--gray-4);
          border-radius: 4px; }
        .arrow {
          width: 16px;
          height: 16px;
          border-radius: 4px;
          background-color: var(--gray-4);
          margin-left: var(--primary-space); } }
      &:nth-child(1) {
        .text {
          width: 39px; }
        .value {
          width: 73px; } }
      &:nth-child(2) {
        .text {
          width: 77px; }
        .value {
          width: 68px; } }
      &:nth-child(3) {
        .text {
          width: 87px; }
        .value {
          width: 67px; } } } }
  .filters {
    display: flex;
    justify-content: flex-end;
    margin: 23px 0 2px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      width: 39px;
      height: 39px;
      border: 1px solid var(--gray-5);
      border-radius: 100%;
      margin-left: 8px;
      position: relative;
      @include PopUpStepAnimation(0.5s);
      @include animationDelay(1s); } }
  .transactionsWrapper {
    margin-top: 50px;
    .transaction {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      @include pulseSkeletonAnimation(1s, 0s);
      .left {
        display: flex;
        align-items: center;
        .icon {
          width: 24px;
          height: 24px;
          background-color: var(--gray-4);
          border-radius: 4px;
          margin-right: 16px; }
        .text {
          height: 48px;
          background-color: var(--gray-4);
          border-radius: 4px; } }
      .right {
        display: flex;
        align-items: center;
        .value {
          height: 22px;
          background-color: var(--gray-4);
          border-radius: 4px; }
        .arrow {
          height: 16px;
          width: 16px;
          border-radius: 4px;
          margin-left: var(--primary-space); } }
      &:nth-child(3n + 1) {
        .left {
          .text {
            width: 127px; } }
        .right {
          .value {
            width: 73px; }
          .arrow {
            background-color: var(--gray-4); } } }
      &:not(:nth-child(3n + 1)) {
        .left {
          .text {
            width: 59px; } }
        .right {
          .value {
            width: 69px; } } } }
    .dividerBottom {
      width: calc( 100% + 32px );
      height: 1px;
      margin: 24px 0 0 -16px;
      background-color: var(--gray-4); } }
  .button {
    display: flex;
    justify-content: center;
    margin: 24px 0;
    .seeMore {
      width: 105px;
      height: 40px;
      background-color: var(--gray-4);
      border-radius: 4px;
      @include pulseSkeletonAnimation(1s, 0s); } } }
