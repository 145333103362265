@import '../../../../styles/animations';

.container {
  margin-top: 8px;
  height: 56px; }
.wrapper {
  height: 48px;
  display: flex;
  align-items: center;
  width: var(--primary-total-width);
  padding: 8px var(--primary-space);
  background-color: var(--white-1);
  z-index: 9999999999;
  position: fixed;
  top: 0;
  left: 0;
  .backIcon {
    margin: 0 8px 0px 7px;
    width: 24px;
    font-size: 18px;
    color: var(--blue-1);
    transform: translateY(-3px); }
  .inputWrapper {
    height: 100%;
    background: var(--white-1);
    border: 1px solid var(--gray-5);
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex: 1;
    .closeIcon {
      width: 16px;
      height: 16px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--gray-8);
      @include turnAnimation(360deg, 0);
      margin-right: 16px; }
    &:focus-within {
      background-color: var(--gray-7); }
    .searchIcon {
      width: 16px;
      color: var(--blue-1);
      margin: 0 var(--primary-space);
      font-size: 14px; }
    .input {
      color: var(--blue-1);
      font-size: var(--regular-font-size);
      background-color: transparent;
      flex: 1;
      height: 100%;
      padding-right: 16px;
      &:placeholder {
        color: var(--gray-6); } } } }
