.wrapper {
  width: var(--primary-total-width);
  padding: 0 var(--primary-space) 32px 16px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--blue-1);
    margin-bottom: 10px;
    .title {
      font-size: 20px;
      letter-spacing: -0.1px; }
    .icon {
      width: 16px;
      height: 16px;
      font-size: 12px;
      transform: rotate(270deg);
      transition: 0.5s;
      &.toTop {
        transform: rotate(90deg); } } } }
