.container {
  padding: var(--primary-space);
  width: var(--primary-total-width);
  .wrapper {
    display: flex;
    justify-content: space-between;
    background-color: var(--white-1);
    margin-bottom: 16px;
    .wrapperButton {
      width: calc((100% / 2) - 4px ); } } }
