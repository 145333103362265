.wrapper {
  width: calc(100% - 3px);
  margin: 0 auto;
  .title {
    margin: 6px 0;
    font-size: 24px;
    color: var(--blue-1); }
  .item {
    display: flex;
    width: calc( 100% - 29px );
    height: 56px;
    padding: 0 17px 0 12px;
    margin: 4px 0;
    align-items: center;
    .icon {
      font-size: 20px;
      background-color: var(--gray-7);
      padding: 4px;
      border-radius: 50%; }
    .description {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      color: var(--blue-1);
      line-height: 20px;
      .date {
        line-height: 16px;
        font-size: 12px; } } } }
