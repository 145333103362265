@import './styles/variables';
@import './styles/fonts';
@import './styles/icons';

* {
  margin: 0;
  padding: 0;
  font-family: var(--font);
  transition: 0.3s;
  -webkit-tap-highlight-color: transparent; }

ul, ol {
  list-style: none; }

html {
  height: -webkit-fill-available; }

body, #root {
  height: 100%;
  height: stretch;
  height: -moz-available;
  height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

input {
  border: none;
  outline: none; }

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--gray-3);
  cursor: pointer; }

*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  cursor: pointer;
  background-color: var(--gray-3); }

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--gray-2); }
