@import '../../styles/animations';

.wrapper {
  @include popUpAnimation(0.5s);
  padding: var(--primary-space);
  width: var(--primary-total-width);
  border-radius: 4px;
  position: relative;
  background-color: var(--white-1);
  color: var(--blue-1);
  z-index: var(--ob-tooltip-z-index);
  .top {
    display: flex;
    height: 24px;
    align-items: center;
    .title {
      font-weight: 700;
      font-size: 20px;
      flex: 0.95;
      align-self: end; }
    .close {
      font-size: 12px;
      color: var(--blue-1);
      margin: 0 3px 0 auto; } }
  .text {
    margin: 8px 0;
    width: 100%;
    font-size: var(--regular-font-size);
    line-height: 20px; }
  .bottom {
    display: flex;
    align-items: center;
    .steps {
      flex: 1;
      display: flex;
      .step {
        border-radius: 100%;
        width: 8px;
        height: 8px;
        background-color: var(--gray-5);
        margin-right: 6px;
        &.active {
          background-color: var(--green-1); } } }
    .button {
      margin-left: var(--primary-space);
      &.rotate {
        transform: rotate(180deg); } } }
  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    &.bottom {
      border-top: 14.018px solid var(--white-1);
      bottom: -10px; }
    &.right {
      right: 15px; }
    &.top {
      border-bottom: 14.018px solid var(--white-1);
      top: -10px; }
    &.left {
      left: 15px; }
    &.none {
      display: none; } } }
