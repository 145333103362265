.wrapper {
  position: fixed;
  width: calc( 100% - 64px );
  min-height: calc( 56px - 32px );
  margin: var(--primary-space);
  background-color: var(--blue-1);
  border-radius: 4px;
  gap: 16px;
  padding: var(--primary-space);
  display: flex;
  transition: 1s;
  top: -70px;
  opacity: 0;
  z-index: 99999999;
  &.show {
    top: 0;
    opacity: 1; }
  &.error {
    background-color: var(--red-2); }
  .icon {
    font-size: 22px;
    margin-right: 10px; }
  .success {
    color: var(--green-1); }
  .alert {
    color: var(--yellow-1); }
  .message {
    align-self: center;
    margin-left: -16px;
    font-size: var(--regular-font-size);
    color: var(--white-1); } }
