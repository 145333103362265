.button {
  width: 35px;
  height: 35px;
  background: var(--green-1);
  border-radius: 32px;
  border: 0;
  transition: 0.7s ease-out;
  &.disabled {
    background: var(--gray-9); }
  &.secundary {
    background-color: var(--gray-7);
    .icon {
      color: var(--green-1); } }
  .icon {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    color: var(--white-1); } }
