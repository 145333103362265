@import '../../../../styles/animations';

.wrapper {
  padding: 0 var(--primary-space);
  color: var(--blue-1);
  width: var(--primary-total-width);
  margin: var(--primary-space) 0 24px 0;
  .title {
    font-weight: 700;
    font-size: var(--regular-font-size);
    margin-bottom: 8px; }
  .item {
    @include listAnimation(6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    letter-spacing: -0.1px;
    height: 42px; } }
