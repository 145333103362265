@import '../../../../styles/animations';

.box {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  @include fadeInAnimation(1s);
  @include animationDelay(0.5s);
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    padding: 0 var(--primary-space);
    width: var(--primary-total-width);
    .text {
      color: var(--blue-1);
      font-size: 18px;
      letter-spacing: -0.1px; }
    .resume {
      display: flex;
      height: 100%;
      align-items: center;
      .arrow {
        transform: rotate(180deg);
        color: var(--blue-1);
        font-size: 14px;
        margin: 0 0 4px 16px;
        @include turnInAnimation(-180deg, 180deg );
        &.noRoute {
          color: var(--gray-8); } } } }
  &:nth-child(2) {
    animation-delay: 0.3s;
    .item:last-child {
      height: 48px; } } }
