@import '../../../../styles/animations';

.container {
  @include fadeInAnimation(1s);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  .steps {
    width: calc( 100% - 40px );
    margin-top: 25px;
    .stepName {
      font-weight: 700;
      font-size: 24px;
      color: var(--blue-1);
      letter-spacing: -0.1px; } }
  .tooltip {
    position: fixed;
    bottom: 80px;
    right: 12px;
    @include fadeInAnimation(1s);
    .text {
      color: var(--white-1); } } }
