.wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  border: 1.5px solid var(--blue-1);
  transition: 0.3s, border 0s;
  &.square {
    border-radius: 4px; }
  &.active {
    background-color: var(--green-1);
    border: 1.5px solid var(--green-1);
    &.disabled {
      border: 1.5px solid var(--white-1);
      background-color: var(--green-1); } }
  &.disabled {
    cursor: default;
    background-color: var(--white-1);
    opacity: 0.3; }
  .icon {
    font-size: 12px;
    color: var(--white-1); } }
