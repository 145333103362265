@import '../../styles/animations';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .image {
    @include popUpAnimation(0.7s);
    width: 194px;
    height: 162px; } }
.message {
  @include fadeInAnimation(1s);
  margin: 10px;
  color: var(--gray-8);
  font-weight: 400;
  font-size: var(--regular-font-size);
  line-height: 20px; }
