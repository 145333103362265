.wrapper {
  display: flex;
  width: var(--primary-total-width);
  height: 70px;
  padding: 0 var(--primary-space);
  align-items: center;
  justify-content: space-between;
  animation: fromBottom 0.5s ease-out 0.5s both;
  position: fixed;
  z-index: calc( var(--window-cover-z-index) + 1  );
  background: var(--white-1);
  bottom: 0;
  transition: 1s;
  .backButton {
    transition: 0.5s;
    &:active {
      transform: translateX(-15px); }
    &.backButtonHidden {
      visibility: hidden; } }
  .resumeWrapper {
    display: flex;
    align-items: center;
    transition: 0.5s;
    &:active {
      .rotateArrow {
        transform: rotate(180deg) translateX(-15px); } }
    .resume {
      padding-right: var(--primary-space);
      font-size: var(--regular-font-size);
      font-weight: 700;
      color: var(--blue-1);
      transition: 0.5s;
      &.disabled {
        color: var(--gray-8); } }
    .button {
      width: 35px;
      height: 35px;
      background: var(--green-1);
      border-radius: 32px;
      border: 0;
      transition: 0.7s ease-out;
      &.disabled {
        background: var(--gray-9); }
      &.secundary {
        background-color: var(--gray-7);
        .icon {
          color: var(--green-1); } }
      .icon {
        display: flex;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        color: var(--white-1);
        &.rotate {
          transform: rotate(180deg); } } } } }


@keyframes fromBottom {
  from {
    opacity: 0;
    transform: translateY(120px); } }
