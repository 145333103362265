@import "../../../../styles/animations";

.wrapper {
  padding: var(--primary-space);
  width: var(--primary-total-width);
  overflow: hidden;
  @include fadeInAnimation(0.5s);
  .title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.1px;
    color: var(--blue-1);
    margin-bottom: 32px; }
  .radioWrapper {
    margin-top: 32px;
    display: flex;
    align-items: center;
    color: var(--blue-1);
    transition: 0.5s;
    font-size: 16px;
    &.disabled {
      color: var(--gray-5); }
    .radio {
      margin-right: 8px; } }
  .button {
    position: fixed;
    width: var(--primary-total-width);
    padding: var(--primary-space);
    bottom: 0;
    left: 0; } }
