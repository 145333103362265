@import '../../../../styles/animations';

.container {
  .wrapper {
    overflow: hidden;
    height: 90vh;
    @include pulseSkeletonAnimation(1s, 0s);
    .detail {
      display: flex;
      justify-content: space-between;
      padding: 0 var(--primary-space);
      margin-top: 16px;
      .detailTitle {
        height: 28px;
        width: 116px;
        background-color: var(--gray-4);
        border-radius: 4px; }
      .detailValue {
        width: 41px;
        height: 20px;
        background-color: var(--gray-4);
        border-radius: 4px; } }
    .customerInfo {
      display: flex;
      flex-direction: column;
      padding: 0 var(--primary-space);
      margin: 24px 0 20px 0;
      .customerTitle {
        width: 125px;
        height: 20px;
        border-radius: 4px;
        background-color: var(--gray-4);
        margin-bottom: 8px; }
      .customerText {
        width: 230px;
        height: 24px;
        border-radius: 4px;
        background-color: var(--gray-4); } }
    .debtTitle {
      width: 116px;
      height: 28px;
      border-radius: 4px;
      background-color: var(--gray-4);
      margin: 24px 0 24px 16px; }
    .debtInfo {
      display: flex;
      justify-content: space-between;
      padding: 0 var(--primary-space);
      margin-bottom: 24px;
      .debtText {
        width: 90px;
        height: 22px;
        border-radius: 4px;
        background-color: var(--gray-4); }
      .debtValue {
        width: 90px;
        height: 32px;
        border-radius: 4px;
        background-color: var(--gray-4); } }
    .buttonsWrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 var(--primary-space);
      .button {
        height: 40px;
        width: calc((100% / 2) - 8px );
        border-radius: 4px;
        background-color: var(--gray-4); } }
    .whatsapp {
      height: 48px;
      border-radius: 4px;
      background-color: var(--gray-4);
      margin: var(--primary-space) var(--primary-space); }
    .transactionsTitle {
      margin: var(--primary-space) var(--primary-space);
      width: 190px;
      height: 28px;
      border-radius: 4px;
      background-color: var(--gray-4); }
    .date {
      width: 28px;
      height: 20px;
      border-radius: 4px;
      background-color: var(--gray-4);
      margin: 0 16px 8px 16px; }
    .transactionWrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 var(--primary-space);
      align-items: center;
      .transactionInfo {
        display: flex;
        flex-direction: column;
        .type {
          width: 145px;
          height: 22px;
          border-radius: 4px;
          background-color: var(--gray-4);
          margin-bottom: 4px; }
        .time {
          width: 145px;
          height: 18px;
          border-radius: 4px;
          background-color: var(--gray-4); } }
      .value {
        width: 65px;
        height: 20px;
        border-radius: 4px;
        background-color: var(--gray-4); } } } }
