.wrapper {
  display: flex;
  justify-content: space-between;
  padding: var(--primary-space);
  margin-bottom: 8px;
  .left {
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 400;
      font-size: var(--regular-font-size);
      line-height: 20px; } }
  .right {
    font-weight: 400;
    font-size: var(--regular-font-size);
    line-height: 20px;
    color: var(--green-1); } }
