@import '../../../../styles/animations';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-1);
  height: calc( 100vh - 170px );
  @include fadeInAnimation(0.5s);
  .pickers {
    width: 100%;
    margin-bottom: 80px;
    scroll-behavior: smooth;
    overflow-y: auto; }
  .button {
    position: fixed;
    background-color: var(--white-1);
    bottom: 0;
    padding: var(--primary-space);
    width: var(--primary-total-width);
    @include fromBottomAnimation(0.5s, 100px); } }
