.loader {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--window-cover-z-index);
  background-color: var(--transparent-4);
  display: flex; }
