@mixin fromRightTextAnimation($duration) {
  @keyframes fromRightText {
    from {
      opacity: 0;
      transform: translateX(100px); }
    to {
      opacity: 1; } }
  animation: fromRightText $duration ease-in; }

@mixin popUpAnimation($duration) {
  @keyframes pupUp {
    from {
      transform: scale(0);
      opacity: 0; }
    to {
      opacity: 1; } }
  animation: pupUp $duration ease-out; }

@mixin fadeInAnimation($duration) {
  @keyframes fadeIn {
    from {
      opacity: 0; }
    to {
      opacity: 1; } }
  animation: fadeIn $duration ease-in-out; }

@mixin dropDownAnimation($duration, $height) {
  @keyframes dropDown {
    from {
      height: 0;
      overflow: hidden; }
    to {
      overflow: hidden;
      height: $height; } }
  animation: dropDown $duration ease-in-out; }

@mixin turnAnimation($from, $to) {
  @keyframes turn {
    from {
      transform: scale(1.001) rotate($from); }
    to {
      transform: scale(1.001) rotate($to); } }
  animation: turn 0.7s ease; }

@mixin turnInAnimation($from, $to) {
  @keyframes turnIn {
    from {
      opacity: 0;
      transform: scale(1.001) rotate($from); }
    to {
      opacity: 1;
      transform: scale(1.001) rotate($to); } }
  animation: turnIn 0.7s ease; }

@mixin stepAnimation() {
  @keyframes step {
    0% {
      opacity: 0;
      transform: scale(0); }
    50% {
      box-shadow: 0px -3px 19px -6px rgba(0, 0, 0, 0.557);
      transform: scale(1.2); } }
  animation: step 0.7s ease-in; }

@mixin tapItemListAnimation() {
  @keyframes tapItemList {
    0% {
      color: var(--green-1); }
    60% {
      transform: translateX(30px); }
    100% {
      transform: translateX(0px);
      color: var(--green-1); } }
  animation: tapItemList 0.3s ease-out; }

@mixin fromLeftAnimation($duration, $translate) {
  @keyframes fromLeft {
    from {
      opacity: 0;
      transform: translateX(-$translate); }
    to {
      opacity: 1; } }
  animation: fromLeft $duration ease-out; }

@mixin fromRightAnimation($duration, $translate) {
  @keyframes fromRight {
    from {
      opacity: 0;
      transform: translateX($translate); }
    to {
      opacity: 1; } }
  animation: fromRight $duration ease-out; }

@mixin fromTopAnimation($duration, $translate) {
  @keyframes fromtop {
    from {
      opacity: 0;
      transform: translateY(-$translate); }
    to {
      opacity: 1; } }
  animation: fromtop $duration ease-out; }

@mixin PopUpStepAnimation($duration) {
  @keyframes popUpStep {
    0% {
      transform: scale(0);
      opacity: 0; }
    70% {
      transform: scale(1.1); }
    100% {
      opacity: 1; } }
  animation: popUpStep $duration ease; }

@mixin fromBottomAnimation($duration, $translate) {
  @keyframes frombottom {
    from {
      opacity: 0;
      transform: translateY(+$translate); }
    to {
      opacity: 1; } }
  animation: frombottom $duration ease-out; }

@mixin listAnimation($total) {
  animation: fromRight 500ms ease both;
  @for $i from 1 through $total {
    &:nth-child(#{$i}) {
      animation-delay: $i * 80ms; } }
  @keyframes fromRight {
    from {
      opacity: 0;
      transform: translateX(300px); }
    to {
      opacity: 1; } } }

@mixin cascadeSkeletonAnimation($duration, $quantity) {
  animation: cascade $duration ease;
  @for $i from 1 through $quantity {
    &:nth-child(#{$i}) {
      @include animationDelay($i * 120ms); } }
  @keyframes cascade {
    0% {
      opacity: 0;
      transform: translate(100px, -15px); } } }

@mixin pulseSkeletonAnimation($duration1, $duration2) {
  animation: pulse $duration1 linear $duration2 both infinite;
  @keyframes pulse {
    20% {
      opacity: 1; }
    50% {
      opacity: 0.5; }
    80% {
      opacity: 1; } } }

@mixin animationDelay($duration) {
  animation-delay: $duration;
  animation-fill-mode: both; }

@mixin fromRightSkeletonAnimation($duration, $translate) {
  @keyframes fromRightS {
    from {
      opacity: 0;
      transform: translateX($translate); }
    to {
      opacity: 1; } }
  animation: fromRightS $duration ease-in-out; }
