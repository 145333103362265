@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.5s);
  width: calc( 100% - 40px );
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  .tooltip {
    margin-top: 16px;
    @include fadeInAnimation(1s);
    .text {
      color: var(--white-1); } } }
