@import '../../../../styles/animations';

.wrapper {
  margin: 0 0 var(--primary-space) 0;
  display: flex;
  flex-direction: column;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: -0.1px;
    height: 64px;
    padding: 0 var(--primary-space);
    width: var(--primary-total-width);
    .left {
      height: 48px;
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      justify-content: center;
      .text {
        font-size: 14px;
        color: var(--gray-8); } }
    .edit {
      color: var(--green-1);
      &.active {
        color: var(--gray-6);
        animation: tap 0.5s ease; } } } }

@keyframes tap {
  50% {
    transform: scale(1.1); } }
