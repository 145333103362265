@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.3s);
  display: flex;
  flex-direction: column;
  background-color: var(--white-1);
  width: 100%;
  margin-bottom: 8px;
  .title {
    font-size: 24px;
    letter-spacing: -0.1px;
    color: var(--blue-1);
    width: var(--primary-total-width);
    padding: var(--primary-space); }
  .divider {
    @include fadeInAnimation(0.3s);
    margin: 8px 0; } }
