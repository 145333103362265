@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.5s);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.1px;
  width: var(--primary-total-width);
  padding: var(--primary-space);
  .title {
    font-size: 18px;
    font-weight: 700;
    color: var(--gray-8); }
  .text {
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-8); }
  .clickableField {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    .clickableText {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      color: var(--green-1); }
    .arrow {
      font-size: 16px;
      margin-left: 4px;
      transform: rotate(180deg);
      margin-bottom: 2px;
      color: var(--green-1); } } }
