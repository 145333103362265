@import '../../styles/animations';

.top {
  margin-top: 18px;
  @include popUpAnimation(0.5s); }
.bottom {
  position: fixed;
  right: 16px;
  bottom: 80px;
  animation: popUp 0.5s ease; }
.text {
  color: var(--white-1); }

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0); } }
