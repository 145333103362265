.divider {
  width: 100%;
  height: 16px;
  background-color: var(--gray-7);
  &.small {
    height: 1px; }
  &.darker {
    background-color: var(--gray-5); }
  &.gray25 {
    background-color: var(--gray-4); } }
