@import '../../../../styles/animations';

.wrapper {
  display: flex;
  justify-content: space-between;
  width: var(--primary-total-width);
  padding: 0 var(--primary-space);
  background-color: var(--white-1);
  margin: 24px 0;
  position: relative;
  .item {
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    flex-direction: column;
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) .wrapperIcon {
        animation: popUp 0.5s ease both;
        animation-delay: 1000ms - (150ms * $i); }
      &:nth-child(#{$i}) .text {
        @include popUpAnimation(0.3s);
        @include animationDelay(750ms + (150ms * $i)); } }
    .wrapperIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: var(--gray-4);
      border: 2px solid var(--white-1);
      z-index: var(--ob-tooltip-z-index);
      .icon {
        font-size: 22px;
        color: var(--blue-1); } }
    &:nth-child(2), &:nth-child(3) {
      .icon {
        font-size: 19px; } }
    .text {
      margin-top: 4px;
      font-size: var(--regular-font-size);
      color: var(--blue-1); } } }

@keyframes popUp {
  0% {
    transform: scale(0);
    opacity: 0; }
  60% {
    transform: scale(1.1); } }
