@import '../../../../styles/animations';

.wrapper {
  @include fromTopAnimation(0.5s, 100px);
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .list {
    width: calc( 100% - 40px );
    .listTitle {
      @include fadeInAnimation(0.5s);
      display: inline-block;
      font-size: 12px;
      line-height: 16px; }
    .item {
      @include listAnimation(10);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 38px;
      transition: 0.5s;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      color: var(--black-1);
      .bold {
        font-weight: bold; }
      .icon {
        transform: rotate(45deg);
        font-size: 12px;
        color: var(--gray-8); } } }
  .createProvider {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: var(--regular-font-size);
    line-height: 20px;
    @include fadeInAnimation(0.5s);
    @include animationDelay(1s);
    .icon {
      color: var(--green-1);
      font-size: 22px; }
    .text {
      display: inline-block;
      margin-left: 8px;
      font-size: var(--regular-font-size);
      line-height: 20px; }
    .createProviderName {
      font-weight: 700;
      margin-left: 5px; } } }
