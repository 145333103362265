@import '../../styles/animations';

.wrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
  transition: 0.5s;
  padding: 0 18px;
  background: var(--white-1);
  border: 1px solid var(--gray-5);
  border-radius: 4px;
  &.active {
    .input {
      background-color: var(--white-1);
      border-color: var(--gray-5); } }
  &.error {
    .input {
      border-color: var(--red-1); } }
  &.large {
    width: 100%; }
  .leftIcon {
    color: var(--blue-1);
    font-size: 14px;
    margin-right: 10px;
    z-index: 1000; }
  .searchIcon {
    color: var(--blue-1);
    font-size: var(--regular-font-size);
    margin-right: 10px; }
  .input {
    width: 100%;
    font-size: var(--regular-font-size);
    border-radius: 4px; }
  .icon {
    position: absolute;
    color: var(--blue-1);
    right: 6px;
    font-size: 12px;
    animation: step 1s ease;
    transition: 0.5s;
    padding: 10px;
    border-radius: 100%;
    &::placeholder {
      color: var(--gray-6);
      margin: 0px 8px; } } }


@keyframes step {
  0% {
    opacity: 0;
    transform: scale(0) rotate(360deg);
    background-color: var(--green-1); }
  60% {
    transform: scale(1.5); } }
