@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.5s);
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  .transactionsWrapper {
    transform: translateY(-24px); }
  .emptyState {
    margin-top: 8px;
    margin-bottom: 24px; } }
