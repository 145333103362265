@import "../../../../styles/animations";

.container {
  @include fadeInAnimation(0.3s);
  .wrapper {
    height: 90vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @include pulseSkeletonAnimation(1s, 0s);
    .info {
      margin: 16px 16px 24px 16px;
      width: 90%;
      height: 51px;
      border-radius: 4px;
      background-color: var(--gray-4); }
    .title {
      margin: 24px var(--primary-space);
      width: 129px;
      height: 28px;
      border-radius: 4px;
      background-color: var(--gray-4); }
    .list {
      padding: 0 var(--primary-space);
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--gray-4);
      .animation {
        .day {
          height: 20px;
          width: 27px;
          border-radius: 4px;
          background-color: var(--gray-4); }
        .transaction {
          margin: 24px 0;
          height: 48px;
          width: 100%;
          border-radius: 4px;
          background-color: var(--gray-4); } } } } }
