@import "../../../../styles/animations";

.container {
  @include fadeInAnimation(0.3s);
  .wrapper {
    width: var(--primary-total-width);
    padding: 0 var(--primary-space);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include pulseSkeletonAnimation(1s, 0s);
    .date {
      margin: var(--primary-space) 0;
      width: 89px;
      height: 16px;
      border-radius: 4px;
      background-color: var(--gray-4); }
    .list {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--gray-4);
      .animation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--primary-space);
        .transaction {
          display: flex;
          align-items: center;
          .icon {
            width: 24px;
            height: 24px;
            margin-right: 16px;
            border-radius: 4px;
            background-color: var(--gray-4); }
          .info {
            height: 48px;
            .title {
              height: 22px;
              width: 98px;
              margin-bottom: 8px;
              border-radius: 4px;
              background-color: var(--gray-4); }
            .time {
              width: 48px;
              height: 18px;
              border-radius: 4px;
              background-color: var(--gray-4); } } }
        .money {
          width: 119px;
          height: 22px;
          border-radius: 4px;
          background-color: var(--gray-4); } } } } }
