@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.5s);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--primary-space) 0 80px;
  .amount {
    width: calc( 100% - 40px );
    padding-top: 8px; } }
