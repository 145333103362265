@import '../../../../styles/animations';

.wrapper {
  display: flex;
  width: calc( 100% - 40px );
  align-items: center;
  .backArrow {
    @include fromLeftAnimation(0.6s, 100px);
    position: absolute;
    display: none;
    &.active {
      position: relative;
      display: inline-block;
      padding-right: 18px; } }
  .inputWrapper {
    display: flex;
    width: calc( 100% - 20px );
    align-items: center;
    position: relative;
    height: 48px;
    transition: 0.5s;
    padding: 0 10px;
    background: var(--white-1);
    border: 1px solid var(--gray-5);
    border-radius: 4px;
    &.active {
      .input {
        background-color: var(--white-1);
        border-color: var(--gray-5); } }
    &.error {
      .input {
        border-color: var(--red-1); } }
    .searchIcon {
      color: var(--blue-1);
      font-size: 14px;
      margin-right: 10px;
      z-index: 1000v; }
    .input {
      width: 80%;
      font-size: var(--regular-font-size);
      border-radius: 4px;
      &::placeholder {
        font-size: 12px;
        line-height: 16px;
        color: var(--gray-8); } }
    .icon {
      position: absolute;
      color: var(--blue-1);
      right: 6px;
      font-size: 12px;
      animation: step 1s ease;
      transition: 0.5s;
      padding: 10px;
      border-radius: 100%; } } }

@keyframes step {
  0% {
    opacity: 0;
    transform: scale(0) rotate(360deg);
    background-color: var(--green-1); }
  60% {
    transform: scale(1.5); } }
