.wrapper {
  display: flex;
  align-items: center;
  color: var(--blue-1);
  transition: 0s;
  .minus {
    display: none;
    transition: 0s; }
  .sign {
    font-size: 12px;
    margin: 0 1px 2px 0;
    transition: 0s;
    &.noChanged {
      color: var(--gray-6); } }
  .input {
    font-size: 18px;
    letter-spacing: -0.1px;
    width: 30px;
    transition: 0s;
    &.income, &.base {
      color: var(--blue-1); }
    &.noChanged {
      color: var(--gray-6); } }
  .invisibleDiv {
    position: fixed;
    top: -400px;
    visibility: hidden; } }
.normal {
  font-size: 18px;
  letter-spacing: -0.1px; }
.large {
  letter-spacing: -0.1px;
  font-size: 28px; }
.expense, .loan {
  color: var(--red-1); }
.gray {
  color: var(--gray-8); }
.expense, .loan, .credit {
  .minus {
    display: inline; } }
.deletion {
  color: var(--gray-6);
  text-decoration: line-through; }
.negative {
  color: var(--red-1);
  .minus {
    display: inline; } }
.regular, .zero {
  color: var(--blue-1); }
.zero {
  .minus {
    display: none; } }
