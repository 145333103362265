@import '../../../../styles/animations';

.wrapper {
  padding: var(--primary-space);
  width: var(--primary-total-width);
  overflow: hidden;
  transition: 0.3s;
  @include fadeInAnimation(0.5s);
  .phoneField {
    padding: 0 var(--primary-space);
    width: var(--primary-total-width);
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    border: 1px solid var(--gray-6);
    border-radius: 4px;
    transition: 0.3s;
    &:focus-within {
      border-color: var(--blue-1); }
    &.hasError {
      border: 1px solid var(--red-1); }
    .flagWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 22px;
      color: var(--blue-1);
      font-size: 18px;
      letter-spacing: -0.1px;
      .img {
        width: 16px;
        height: 13px;
        margin: 0 6px 2px 0; } } }
  .error {
    margin: 4px 16px;
    font-size: 14px;
    min-height: 18px;
    line-height: 18px;
    display: flex;
    align-items: flex-end;
    color: var(--red-1);
    @include fadeInAnimation(0.3s); }
  .button {
    position: fixed;
    width: var(--primary-total-width);
    padding: var(--primary-space);
    bottom: 0;
    left: 0; } }
