@import '../../styles/animations';

.wrapper {
  margin: 24px 0 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include fadeInAnimation(1s);
  animation-fill-mode: both;
  height: 40px;
  .button {
    width: 105px;
    @include fadeInAnimation(1s);
    @include animationDelay(1000ms); } }
