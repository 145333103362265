.wrapper {
  .searchBar {
    animation: move 0.3s ease;
    display: flex;
    align-items: center;
    height: 36px;
    width: var(--primary-total-width);
    margin: var(--primary-space);
    background-color: var(--gray-7);
    border-radius: 4px;
    color: var(--gray-8);
    .searchIcon {
      font-size: 16px;
      color: var(--blue-1);
      margin: 0 var(--primary-space); } }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: var(--primary-total-width);
    padding: 0 var(--primary-space);
    font-size: 18px;
    color: var(--blue-1); } }
