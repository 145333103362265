@import '../../../../styles/animations';

.wrapper {
  width: calc( 100% - 40px );
  padding-top: var(--primary-space);
  .title {
    text-align: left;
    font-weight: 700;
    font-size: var(--regular-font-size);
    line-height: 20px;
    color: var(--blue-1); }
  .items {
    justify-content: left;
    margin: 8px auto 16px 0;
    .item {
      display: flex;
      align-items: center;
      text-align: left;
      @include listAnimation(6);
      background-color: var(--gray-10);
      width: 100vw;
      position: relative;
      left: -20px;
      box-sizing: border-box;
      padding: 8px 20px;
      & + div.item {
        margin-top: 4px; }
      .itemInfo {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          flex: 1;
          .history {
            font-size: 14px;
            color: var(--gray-9);
            margin-right: 8px;
            transform: scaleX(-1); }
          .text {
            font-size: 12px;
            line-height: 16px;
            color: var(--gray-8); } }
        .right {
          display: flex;
          align-items: center;
          .delete {
            margin: 0 12px 0 10px;
            font-weight: 700;
            font-size: 10px;
            color: var(--gray-8); }
          .icon {
            transform: rotate(45deg);
            font-size: 14px;
            color: var(--gray-8); } } } } }
  .emptyState {
    display: inline-block;
    margin: var(--primary-space) 0;
    font-size: 12px;
    line-height: 16px; } }

