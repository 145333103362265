@import '../../../../styles/animations';

.wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  transition: 0.3s;
  @include popUpAnimation(0.5s);
  .text {
    color: var(--white-1); }
  &.top1 {
    top: 140px; }
  &.top2 {
    top: 215px; }
  &.top3 {
    top: 200px; }
  &.bottom {
    bottom: 80px;
    animation: popUp 0.5s ease; } }

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0); } }
