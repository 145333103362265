@import '../../styles/animations';

.wrapper {
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid var(--gray-6);
  border-radius: 4px;
  transition: 0.7s;
  overflow: hidden;
  &.disabled {
    background-color: var(--gray-2);
    border-color: var(--gray-3); }
  &:focus-within {
    border: 1px solid var(--blue-1); }
  &.error {
    border: 1px solid var(--red-1); }
  .left {
    width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    margin: 0 var(--primary-space);
    flex-direction: column;
    justify-content: center;
    .label {
      font-size: 14px;
      font-weight: 400;
      color: var(--gray-8);
      @include fadeInAnimation(0.5s);
      margin-bottom: 4px; }
    .inputWrapper {
      display: flex;
      width: calc( 100% - 30px );
      &.show {
        .input {
          text-align: left; }
        .sign {
          transition: 0.5s;
          display: inline;
          animation: bottom 0.5s ease;
          @keyframes bottom {
            from {
              opacity: 0;
              transform: translateY(15px); } } } }
      .sign {
        display: none;
        font-size: 18px;
        margin-right: 3px;
        color: var(--blue-1); }
      .input {
        width: 100%;
        font-size: 18px;
        color: var(--blue-1);
        background-color: transparent;
        transition: 1s;
        text-align: left; } } } }
.errorMessage {
  opacity: 1;
  min-height: 18px;
  display: flex;
  align-items: flex-end;
  line-height: 18px;
  font-size: 14px;
  margin: 4px 16px 0 16px;
  color: var(--red-1);
  @include fadeInAnimation(0.3s); }
