@import '../../../../styles/animations';

.wrapper {
  position: absolute;
  width: calc( 100% - 32px );
  padding: 0 16px;
  left: 0;
  top: 80px;
  &::before {
    content: "";
    position: fixed;
    background-color: var(--ob-cover-background);
    z-index: var(--ob-cover-z-index);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0; }
  .blocker {
    position: fixed;
    width: 100%;
    height: 50px;
    left: 0;
    top: 55px;
    z-index: var(--ob-tooltip-z-index); }
  .trianguleWrapper {
    position: relative;
    padding-left: 16px;
    width: calc( 100% - 32px );
    display: flex;
    justify-content: space-between;
    .item {
      width: 0;
      height: 0;
      z-index: var(--ob-tooltip-z-index);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 13px solid var(--white-1);
      visibility: hidden;
      &.current {
        @include fromBottomAnimation(0.3s, 20px);
        @include animationDelay(1s);
        visibility: visible; } } } }
