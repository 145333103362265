@import '../../styles/animations';

.wrapper {
  width: 100%;
  height: 56px;
  &.hidden {
    display: none; }
  .fixed {
    @include fromTopAnimation(0.5s, 100px);
    @include animationDelay(0.3s);
    background-color: var(--white-1);
    display: flex;
    height: 56px;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-tap-highlight-color: transparent;
    z-index: calc( var(--ob-cover-z-index) - 1 );
    &.under {
      z-index: 0; }
    &.hidden {
      display: none; }
    .icon, .iconLeft, .title {
      z-index: calc( var(--ob-cover-z-index) - 1 ); }
    .icon {
      position: absolute;
      right: 0;
      color: var(--blue-1);
      margin-right: 21.25px;
      cursor: pointer;
      transition: 0.3s;
      right: -10px;
      padding: 10px;
      border-radius: 100%;
      @include turnAnimation(220deg, 0deg);
      &:hover {
        animation: active 0.5s ease;
        @keyframes active {
          30% {
            background-color: var(--green-1); } } } }
    .iconLeft {
      margin: 0 7px 0 23.5px;
      font-size: 18px;
      color: var(--blue-1);
      cursor: pointer; }
    .title {
      margin-left: var(--primary-space);
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.1px;
      color: var(--blue-1);
      text-align: left; } } }
