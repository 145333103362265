@import '../../../../styles/animations';

.title, .total {
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  color: var(--blue-1); }
.title {
  height: 60px;
  padding: 0 var(--primary-space);
  font-size: 24px;
  .button {
    transition: 0.3s;
    color: var(--green-1);
    font-size: 16px;
    &.active {
      color: var(--gray-11);
      animation: tap 0.5s ease; } } }
.total {
  height: 42px;
  padding: var(--primary-space);
  width: var(--primary-total-width);
  justify-content: space-between;
  font-size: 20px; }
