@import '../../styles/animations';

.wrapper {
  display: flex;
  background-color: var(--white-1);
  height: 64px;
  align-items: center;
  @include fromRightAnimation(0.5s, 300px);
  animation-fill-mode: both;
  animation-timing-function: ease;
  padding: 0 var(--primary-space);
  width: var(--primary-total-width);
  &.withoutIcons {
    .icon, .arrow {
      display: none; } }
  &.withoutAnimation {
    animation: none; }
  .icon {
    margin-right: 16px;
    font-size: 18px;
    color: var(--blue-1); }
  .description {
    flex: 1;
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      align-items: flex-end;
      height: 24px;
      font-size: 18px;
      font-weight: 400;
      color: var(--blue-1);
      line-height: 22px; }
    .subtitle {
      color: var(--gray-8);
      font-size: 14px;
      margin-top: 4px;
      font-weight: 400; } }
  .arrow {
    transform: rotate(180deg);
    margin: 0 0 3px 16px;
    color: var(--blue-1);
    font-size: 12px;
    &.hidden {
      visibility: hidden; } } }
.base, .closure {
  .icon {
    font-size: 20px;
    margin-right: 14px; } }
.loan, .paymentLoan {
  .icon {
    color: var(--blue-1);
    font-size: 20px; } }
.deletion {
  .icon {
    color: var(--gray-6); }
  .description {
    color: var(--gray-6);
    text-decoration: line-through;
    .subtitle {
      color: var(--gray-6); } } }
