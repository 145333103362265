@import '../../../../styles/animations';

.buttons, .button {
  position: fixed;
  bottom: 0;
  padding: var(--primary-space);
  width: calc( 100% - 32px ); }
.buttons {
  display: grid;
  row-gap: 16px;
  @include fromBottomAnimation(0.3s, 80px); }
.button {
  @include fadeInAnimation(0.3s); }
