@import '../../../../styles/animations';

.wrapper {
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-1);
  min-height: 300px;
  &.topSearchBar {
    width: 100%;
    height: calc(100vh - 12px);
    position: absolute;
    top: 0px;
    margin-top: 12px;
    background-color: var(--white-1);
    z-index: 1000000000;
    animation: toTop 0.3s ease-out; } }
.toBottom {
  animation: toBottom 0.3s ease-out; }

@keyframes toBottom {
  0% {
    transform: translateY(-120px); } }

@keyframes toTop {
  0% {
    transform: translateY(120px); } }
