@import '../../../../styles/animations';

.wrapper {
  @include fadeInAnimation(0.5s);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .deleted {
    width: 100%;
    height: 100%;
    position: fixed;
    background: var(--gray-7);
    opacity: 0.6;
    left: 0;
    top: 0;
    z-index: 10; }
  .debtsTitle {
    font-size: 24px;
    line-height: 28px;
    color: var(--blue-1);
    margin-top: 8px;
    padding: var(--primary-space);
    letter-spacing: -0.1px; }
  .debts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white-1);
    height: 48px;
    padding: 0 var(--primary-space);
    .text {
      color: var(--blue-1);
      font-size: 18px;
      letter-spacing: -0.1px; } }
  .transactionsTitle {
    color: var(--blue-1);
    font-size: 24px;
    line-height: 28px;
    padding: var(--primary-space);
    letter-spacing: -0.1px; } }
