@import '../../styles/animations';

.wrapper {
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid var(--gray-6);
  overflow: hidden;
  transition: 0.3s;
  &.disabled {
    background-color: var(--gray-2);
    border-color: var(--gray-3); }
  &:focus-within {
    border: 1px solid var(--blue-1); }
  &.error {
    border: 1px solid var(--red-1); }
  &.withoutBorder {
    border: none; }
  .left {
    flex: 1;
    height: 100%;
    display: flex;
    margin: 0 0 0 var(--primary-space);
    flex-direction: column;
    justify-content: center;
    .label {
      font-size: 14px;
      @include fadeInAnimation(0.5s);
      color: var(--gray-8); }
    .input {
      font-size: 18px;
      line-height: 20px;
      color: var(--blue-1);
      background-color: transparent;
      &::placeholder {
        color: var(--gray-6); } } } }
.helperText {
  font-size: 12px;
  line-height: 16px;
  color: var(--gray-6);
  top: calc(100% + 4px);
  left: 16px;
  opacity: 0; }
.errorMessage {
  opacity: 1;
  min-height: 18px;
  display: flex;
  line-height: 18px;
  align-items: flex-end;
  font-size: 14px;
  margin: 4px 16px 0 16px;
  color: var(--red-1);
  @include fadeInAnimation(0.3s); }
