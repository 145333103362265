@import '../../../../styles/animations';

.wrapper {
  width: 100%;
  margin-bottom: var(--primary-space);
  transition: 0.5s;
  overflow: hidden;
  &.top {
    .title {
      display: none; }
    .pickers {
      margin-top: 0; } }
  .title {
    margin: 0 var(--primary-space);
    font-size: 24px;
    color: var(--blue-1);
    letter-spacing: -0.1px;
    position: absolute;
    top: 70px;
    @include fadeInAnimation(1s); }
  .pickers {
    margin-top: 56px;
    transition: 0.5s;
    width: var(--primary-total-width);
    padding: var(--primary-space);
    display: flex;
    justify-content: space-between;
    background-color: var(--white-1);
    z-index: 10;
    .picker {
      width: calc( (100% / 2) - 38px );
      padding: 8px 16px;
      height: 40px;
      border: 1px solid var(--gray-6);
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      transition: 0.5s;
      align-items: center;
      &.focus {
        border-color: var(--blue-1); }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        color: var(--gray-8);
        .date {
          height: 20px;
          margin-top: 4px;
          color: var(--blue-1);
          font-size: var(--regular-font-size);
          display: flex;
          .show {
            @include fromRightAnimation(0.3s, 20px);
            text-transform: capitalize; } } }
      .icon {
        color: var(--blue-1); } } } }
