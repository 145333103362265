$base: '../assets/fonts';

@font-face {
  font-family: 'icomoon';
  src: url('#{$base}/icomoon.eot') format('embedded-opentype'), url('#{$base}/icomoon.ttf') format('truetype'), url('#{$base}/icomoon.woff') format('woff'), url('#{$base}/icomoon.svg') format('svg');
  font-style: normal;
  font-weight: normal;
  font-display: block; }


[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }


.icon-handshake:before {
  content: "\e901"; }
.icon-close:before {
  content: "\e902"; }
.icon-arrow-1:before {
  content: "\e903"; }
.icon-sign-in:before {
  content: "\e904"; }
.icon-credit-card:before {
  content: "\e905"; }
.icon-money-cash:before {
  content: "\e906"; }
.icon-plus-circle:before {
  content: "\e907"; }
.icon-info:before {
  content: "\e908"; }
.icon-search:before {
  content: "\e909"; }
.icon-wallet:before {
  content: "\e900"; }
.icon-check:before {
  content: "\e90a"; }
.icon-back:before {
  content: "\e90b"; }
.icon-light:before {
  content: "\e90c"; }
.icon-user:before {
  content: "\e90d"; }
.icon-phone:before {
  content: "\e90e"; }
.icon-truck:before {
  content: "\e90f"; }
.icon-drop:before {
  content: "\e910"; }
.icon-signal:before {
  content: "\e911"; }
.icon-wind:before {
  content: "\e912"; }
.icon-strategy:before {
  content: "\e913"; }
.icon-check-circle:before {
  content: "\e914"; }
.icon-alert-circle:before {
  content: "\e915"; }
.icon-sign-out:before {
  content: "\e916"; }
.icon-calculator:before {
  content: "\e917"; }
.icon-coins:before {
  content: "\e918"; }
.icon-whatsapp:before {
  content: "\e919"; }
.icon-user-gear:before {
  content: "\e91a"; }
.icon-plus:before {
  content: "\e91b"; }
.icon-minus:before {
  content: "\e91c"; }
.icon-store:before {
  content: "\e91d"; }
.icon-credit-clock:before {
  content: "\e91e"; }
.icon-trash:before {
  content: "\e91f"; }
.icon-income:before {
  content: "\e921"; }
.icon-egress:before {
  content: "\e922"; }
.icon-star:before {
  content: "\e920"; }
.icon-filters:before {
  content: "\e923"; }
.icon-pencil:before {
  content: "\e924"; }
.icon-calendar:before {
  content: "\e925"; }
.icon-user-plus:before {
  content: "\e926"; }

