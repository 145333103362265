@import '../../../../styles/animations';

.container {
  @include fadeInAnimation(0.5s);
  width: var(--primary-total-width);
  margin: 0 auto;
  padding-bottom: 78px;
  .resume {
    width: calc( 100% - 24px );
    height: 78px;
    padding: 0 12px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--regular-font-size);
    color: var(--blue-1); }
  .button {
    position: fixed;
    width: var(--primary-total-width);
    bottom: 16px; } }
